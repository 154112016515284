import ModalDialog from '@/components/Modals/ModalDialog';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  organizationId?: string;
}

const AddUserModal = ({ isOpen, onClose, organizationId }: Props) =>
  isOpen && (
    <ModalDialog
      isDismissable
      data-cy="add-user-modal"
      isOpen={isOpen}
      title="Add Team Member"
      onClose={onClose}
    >
      <div>{organizationId}</div>
    </ModalDialog>
  );

export default AddUserModal;
