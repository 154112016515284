import ModalDialog from '@/components/Modals/ModalDialog';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  organizationId?: string;
  userId?: string;
}

const RemoveUserModal = ({ isOpen, onClose, organizationId, userId }: Props) =>
  isOpen && (
    <ModalDialog
      isDismissable
      data-cy="remove-user-modal"
      isOpen={isOpen}
      title="Remove Team Member"
      onClose={onClose}
    >
      <div>
        Are you sure you want to remove {userId} from org {organizationId}?
      </div>
    </ModalDialog>
  );

export default RemoveUserModal;
