import useWindowDimensions from '@/hooks/useWindowDimensions';
import * as S from './styles';
import { useOverlayTriggerState } from 'react-stately';
import EditUserModal from '../Modals/EditUserModal';

interface Props {
  close: () => void;
  organizationId: string;
  userId: string;
}

const ViewUser = ({ close, organizationId, userId }: Props) => {
  const { width } = useWindowDimensions();
  const { close: closeEditUserModal, isOpen: editUserModalIsOpen } = useOverlayTriggerState({
    defaultOpen: location.pathname.endsWith('/edit')
  });

  return (
    <S.FullOverlay>
      <S.AnimationWrapper
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: width }}
        initial={{
          opacity: document.referrer.includes('my-organization/team/') ? 1 : 0,
          x: width
        }}
        transition={{ duration: 0.6 }}
      >
        <S.FullModal
          isDismissable
          isOpen
          data-cy="view-user-modal"
          title="View user"
          onClose={close}
        >
          <div>
            View user {userId} from org {organizationId}
          </div>
        </S.FullModal>
      </S.AnimationWrapper>
      <EditUserModal
        isOpen={editUserModalIsOpen}
        organizationId={organizationId}
        userId={userId}
        onClose={closeEditUserModal}
      />
    </S.FullOverlay>
  );
};

export default ViewUser;
