import ModalDialog from '@/components/Modals/ModalDialog';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  organizationId?: string;
}

const BulkAddUsersModal = ({ isOpen, onClose, organizationId }: Props) =>
  isOpen && (
    <ModalDialog
      isDismissable
      data-cy="bulk-add-users-modal"
      isOpen={isOpen}
      title="Bulk Add Team Members"
      onClose={onClose}
    >
      <div>Bulk add users form content {organizationId}</div>
    </ModalDialog>
  );

export default BulkAddUsersModal;
