import type { PropsWithChildren } from 'react';
import { useRef } from 'react';
import type { AriaDialogProps, AriaOverlayProps } from 'react-aria';
import { FocusScope, useDialog, useModal, useOverlay, usePreventScroll } from 'react-aria';
import isNonEmptyString from '@/utils/isNonEmptyString';

import * as S from './styles';

interface Props extends AriaDialogProps, AriaOverlayProps {
  className?: string;
  title?: string;
}

const ModalDialog = ({ children, className, title, ...otherProps }: PropsWithChildren<Props>) => {
  const { isDismissable = false, onClose } = otherProps;

  const ref = useRef<HTMLDivElement>(null);
  const { overlayProps, underlayProps } = useOverlay(otherProps, ref);

  usePreventScroll();
  const { modalProps } = useModal();

  const { dialogProps, titleProps } = useDialog(otherProps, ref);

  return (
    <S.ModalDialog {...underlayProps}>
      <FocusScope
        autoFocus
        contain
        restoreFocus
      >
        <S.Dialog
          {...dialogProps}
          {...modalProps}
          {...overlayProps}
          ref={ref}
          className={className}
        >
          {isDismissable && (
            <S.CloseButton.Root
              data-cy="close-modal-btn"
              onPress={onClose}
            >
              <S.CloseButton.Icon />
            </S.CloseButton.Root>
          )}
          {isNonEmptyString(title) && <S.Title {...titleProps}>{title}</S.Title>}
          {children}
        </S.Dialog>
      </FocusScope>
    </S.ModalDialog>
  );
};

export default ModalDialog;
