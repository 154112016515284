import styled from 'styled-components';

import { DESKTOP_BREAKPOINT_RANGE, TEXT_STYLES } from '../../../constants';
import { default as BaseSpinner } from '../../Spinner';

interface ButtonProps {
  $background: 'dark' | 'light';
  $fullWidth: boolean;
  $isFocused: boolean;
  $isHovered: boolean;
  $isLoading: boolean;
  $isPressed: boolean;
  $size: 'large' | 'regular' | 'small';
  $variant: 'destructive' | 'empty' | 'icon' | 'outline' | 'plain' | 'primary';
}

export const Button = styled.button<ButtonProps>`
  all: unset;
  box-sizing: border-box;

  &:is(button) {
    display: inline-block;
  }

  &:is(a) {
    display: inline-flex;
    justify-content: center;
  }

  ${({ $fullWidth }) => {
    if ($fullWidth) {
      return `
        display: block;
        width: 100%;
      `;
    } else {
      return `
        display: inline-block;
      `;
    }
  }}

  ${({ disabled = false }) => {
    if (disabled) {
      return `
        cursor: not-allowed;
      `;
    } else {
      return `
        cursor: pointer;
      `;
    }
  }}

  ${({ $isLoading }) =>
    $isLoading &&
    `
    position: relative;

    ${Text} {
      visibility: hidden;
    }
  `}

  ${({ $size, $variant }) => {
    const sharedStyles = `
      border-radius: 3rem;

      ${DESKTOP_BREAKPOINT_RANGE} {
        font-size: 1.0625rem;
      }
    `;

    if ($size === 'regular' && $variant !== 'icon') {
      if ($variant === 'outline' || $variant === 'plain') {
        return `
          ${sharedStyles}
          ${TEXT_STYLES.buttonTextLargeScreen}
          padding: calc(0.5rem - 1px) calc(1rem - 1px);
        `;
      } else {
        return `
          ${sharedStyles}
          ${TEXT_STYLES.buttonTextLargeScreen}
          padding: 0.5rem 1rem;
        `;
      }
    }

    if ($size === 'large' && $variant !== 'icon') {
      if ($variant === 'outline' || $variant === 'plain') {
        return `
          ${sharedStyles}
          ${TEXT_STYLES.buttonTextLargeScreen}
          padding: calc(0.75rem - 1px) calc(1.5rem - 1px);
        `;
      } else {
        return `
          ${sharedStyles}
          ${TEXT_STYLES.buttonTextLargeScreen}
          padding: 0.75rem 1.5rem;
        `;
      }
    }
  }}

  ${({
    $background,
    $isFocused,
    $isHovered,
    $isLoading,
    $isPressed,
    $variant,
    disabled = false
  }) => {
    if ($variant === 'primary') {
      let sharedStyles = '';

      if (disabled && !$isLoading) {
        return `
          ${sharedStyles}
          color: var(--gray-300);
          background-color: var(--gray-100);
        `;
      }

      sharedStyles = `
        ${sharedStyles}
        color: ${$background === 'dark' ? 'var(--purple-400)' : 'var(--white-light)'};
        background-color: ${$background === 'dark' ? 'var(--white-light)' : 'var(--purple-400)'};
      `;

      if ($isPressed) {
        return `
          ${sharedStyles}
          background-color: ${
            $background === 'dark' ? 'var(--bright-purple-500)' : 'var(--purple-600)'
          };
          color: var(--white-light);
        `;
      }

      if ($isFocused) {
        return `
          ${sharedStyles}
          background-color: ${$background === 'dark' ? 'var(--white-light)' : 'var(--purple-400)'};
          color: ${$background === 'dark' ? 'var(--purple-400)' : 'var(--white-light)'};
          outline-offset: ${$background === 'dark' ? '0' : '1px'};
          outline: 2px solid var(--bright-purple-400);
        `;
      }

      if ($isHovered) {
        return `
          ${sharedStyles}
          background-color: ${
            $background === 'dark' ? 'var(--bright-purple-400)' : 'var(--purple-500)'
          };
          color: var(--light-purple-50);
        `;
      }

      return sharedStyles;
    }

    if ($variant === 'outline') {
      let sharedStyles = `
        border-width: 1px;
        border-style: solid;
      `;

      if (disabled && !$isLoading) {
        return `
          ${sharedStyles}
          background-color:  ${$background === 'dark' ? 'var(--gray-50)' : 'transparent'};
          color: var(--gray-300);
          border-color: var(--gray-300);
        `;
      }

      sharedStyles = `
        ${sharedStyles}
        color: ${$background === 'dark' ? 'var(--white-light)' : 'var(--purple-400)'};
        border-color: ${$background === 'dark' ? 'var(--white-light)' : 'var(--purple-400)'};
      `;

      if ($isPressed) {
        return `
          ${sharedStyles}
          color: ${$background === 'dark' ? 'var(--purple-600)' : 'var(--light-purple-50)'};
          background-color: ${$background === 'dark' ? 'var(--gray-100)' : 'var(--purple-600)'};
          border-color: ${$background === 'dark' ? 'var(--white-light)' : 'var(--purple-600)'};
        `;
      }

      if ($isFocused) {
        return `
          ${sharedStyles}
          outline-offset: 1px;
          outline: 2px solid var(--bright-purple-400);
        `;
      }

      if ($isHovered) {
        return `
          ${sharedStyles}
          color: ${$background === 'dark' ? 'var(--purple-400)' : 'var(--light-purple-50)'};
          background-color: ${$background === 'dark' ? 'var(--white-light)' : 'var(--purple-400)'};
        `;
      }

      return sharedStyles;
    }

    if ($variant === 'destructive') {
      let sharedStyles = '';

      if (disabled && !$isLoading) {
        return `
          ${sharedStyles}
          color: var(--gray-300);
          background-color: var(--gray-100);
        `;
      }

      sharedStyles = `
        ${sharedStyles}
        color: var(--white-light);
        background-color: var(--error-600);
      `;

      if ($isPressed) {
        return `
          ${sharedStyles}
          background-color: var(--error-800);
        `;
      }

      if ($isFocused) {
        return `
          ${sharedStyles}
          outline-offset: 1px;
          outline: 2px solid var(--bright-purple-400);
        `;
      }

      if ($isHovered) {
        return `
          ${sharedStyles}
          color: var(--white-light);
          background-color: var(--error-700);
        `;
      }

      return sharedStyles;
    }

    if ($variant === 'plain') {
      let sharedStyles = `
        border-style: solid;
        border-width: 1px;
      `;

      if (disabled && !$isLoading) {
        return `
          ${sharedStyles}
          color: var(--gray-300);
          border-color: var(--gray-300);
        `;
      }

      sharedStyles = `
        ${sharedStyles}
        color: ${$background === 'dark' ? 'var(--light-purple-50)' : 'var(--gray-800)'};
        border-color: var(--gray-300);
      `;

      if ($isPressed) {
        return `
          ${sharedStyles}
          color: var(--gray-800);
          background-color: var(--gray-200);
        `;
      }

      if ($isFocused) {
        return `
          ${sharedStyles}
          outline-offset: 1px;
          outline: 2px solid var(--bright-purple-400);
        `;
      }

      if ($isHovered) {
        return `
          ${sharedStyles}
          color: var(--gray-800);
          background-color: var(--gray-100);
        `;
      }

      return sharedStyles;
    }

    if ($variant === 'icon') {
      let sharedStyles = `
          display: inline-flex;
          width: 1.875rem;
          height: 1.875rem;
          padding: 0.31rem;
          justify-content: center;
          align-items: center;
          border-radius: 6.25rem;
          background: var(--gray-200);
          color: var(--gray-500);
        `;

      if (disabled && !$isLoading) {
        return `
          ${sharedStyles}
          color: var(--gray-400);
        `;
      }

      sharedStyles = `
        ${sharedStyles}
        color: var(--gray-500);
      `;

      if ($isPressed) {
        return `
          ${sharedStyles}
          background-color: var(--gray-300);
          color: var(--gray-600);
        `;
      }

      if ($isFocused) {
        return `
          ${sharedStyles}
          outline-offset: 1px;
          outline: 2px solid var(--bright-purple-400);
        `;
      }

      if ($isHovered) {
        return `
          ${sharedStyles}
          background-color: var(--gray-300);
          color: var(--gray-600);
        `;
      }

      return sharedStyles;
    }

    // variant === empty
    let sharedStyles = `
      padding: 1em 2em;
    `;

    if ($background === 'light') {
      if (disabled) {
        return `
          ${sharedStyles}
          color: var(--gray-300);
        `;
      }

      sharedStyles = `
        ${sharedStyles}
        color: var(--purple-400);
      `;

      if ($isPressed) {
        return `
          ${sharedStyles}
          color: var(--purple-800);
        `;
      }

      if ($isFocused) {
        return `
          ${sharedStyles}
          outline-offset: 1px;
          outline: 2px solid var(--bright-purple-400);
        `;
      }

      if ($isHovered) {
        return `
          ${sharedStyles}
          color: var(--purple-600);
          text-decoration: underline;
        `;
      }
    }

    if ($background === 'dark') {
      if (disabled) {
        return `
          ${sharedStyles}
          color: var(--gray-300);
        `;
      }

      sharedStyles = `
        ${sharedStyles}
        color: var(--white-light);
      `;

      if ($isPressed) {
        return `
          ${sharedStyles}
          color: var(--bright-purple-400);
        `;
      }

      if ($isFocused) {
        return `
          ${sharedStyles}
          outline-offset: 1px;
          outline: 2px solid var(--bright-purple-400);
        `;
      }

      if ($isHovered) {
        return `
          ${sharedStyles}
          color: var(--white-light);
          text-decoration: underline;
        `;
      }
    }

    return sharedStyles;
  }}
`;

export const LeadingIcon = styled.svg`
  height: 1.25rem;
  width: auto;
  fill: currentcolor;
`;

export const Spinner = styled(BaseSpinner)`
  position: absolute;
  inset: 0;
  margin: auto;
  height: 1.25rem;
  width: auto;
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  column-gap: 0.5rem;
`;

export const TrailingIcon = styled.svg`
  height: 1.25rem;
  width: auto;
  fill: currentcolor;
`;
