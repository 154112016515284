// TODO: import `useObjectRef` from `'react-aria'` once it stops throwing a TS error.
import { useObjectRef } from '@react-aria/utils';
import type { FC, MutableRefObject, SVGProps } from 'react';
import { forwardRef } from 'react';
import type { AriaButtonProps } from 'react-aria';
import { mergeProps, useButton, useFocusRing, useHover } from 'react-aria';
import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import isNonEmptyString from '@/utils/isNonEmptyString';

import * as S from './styles';

interface Props extends AriaButtonProps {
  background?: 'dark' | 'light';
  className?: string;
  fullWidth?: boolean;
  isLoading?: boolean;
  leadingIcon?: FC<SVGProps<SVGSVGElement>>;
  size?: 'large' | 'regular';
  to?: LinkProps['to'];
  trailingIcon?: FC<SVGProps<SVGSVGElement>>;
  variant?: 'destructive' | 'empty' | 'outline' | 'plain' | 'primary' | 'icon';
}

const Button = forwardRef<HTMLAnchorElement | HTMLButtonElement, Props>(
  (
    {
      background = 'light',
      className,
      fullWidth = false,
      isLoading = false,
      leadingIcon,
      size = 'regular',
      to,
      trailingIcon,
      variant = 'primary',
      ...ariaButtonProps
    },
    forwardedRef
  ) => {
    const { children, href, isDisabled = false, target } = ariaButtonProps;

    const ref = useObjectRef(forwardedRef);

    const { buttonProps, isPressed } = useButton(
      { ...ariaButtonProps, isDisabled: isDisabled || isLoading },
      ref
    );
    const { focusProps, isFocusVisible } = useFocusRing();
    const { hoverProps, isHovered } = useHover({ isDisabled });

    let spinnerColor = 'var(--purple-400)';
    let spinnerBgColor = 'var(--gray-100)';
    if (variant === 'outline') {
      spinnerColor = 'var(--gray-100)';
      spinnerBgColor = 'var(--purple-400)';
    } else if (variant === 'destructive') {
      spinnerColor = 'var(--error-400)';
      spinnerBgColor = 'var(--gray-100)';
    } else if (variant === 'plain') {
      spinnerColor = 'var(--gray-100)';
      spinnerBgColor = 'var(--gray-600)';
    }

    const sharedProps = {
      ...mergeProps(buttonProps, focusProps, hoverProps),
      $background: background,
      $fullWidth: fullWidth,
      $isFocused: isFocusVisible,
      $isHovered: isHovered,
      $isLoading: isLoading,
      $isPressed: isPressed,
      $size: size,
      $variant: variant,
      children: (
        <>
          {isLoading && (
            <S.Spinner
              backgroundColor={spinnerBgColor}
              color={spinnerColor}
            />
          )}
          <S.Text>
            {leadingIcon && <S.LeadingIcon as={leadingIcon} />}
            {children}
            {trailingIcon && <S.TrailingIcon as={trailingIcon} />}
          </S.Text>
        </>
      ),
      className
    };

    if (isNonEmptyString(href)) {
      return (
        <S.Button
          {...sharedProps}
          ref={ref as MutableRefObject<HTMLAnchorElement>}
          as="a"
          href={href}
          target={target}
        />
      );
    } else if (to !== undefined) {
      return (
        <S.Button
          {...sharedProps}
          ref={ref as MutableRefObject<HTMLAnchorElement>}
          as={Link}
          state={{ referrer: window.location.pathname, referrerSearch: window.location.search }}
          target={target}
          to={to}
        />
      );
    } else {
      return (
        <S.Button
          {...sharedProps}
          ref={ref as MutableRefObject<HTMLButtonElement>}
        />
      );
    }
  }
);

Button.displayName = 'Button';

export default Button;
