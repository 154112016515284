import * as Sentry from '@sentry/react';
import PageTitle from '@/components/PageTitle';
import type { SortDescriptor } from 'react-stately';
import ExportIcon from '@material-symbols/svg-400/rounded/download.svg?react';
import PersonAddIcon from '@material-symbols/svg-400/rounded/person_add-fill.svg?react';
import GroupAddIcon from '@material-symbols/svg-400/rounded/group_add-fill.svg?react';
import GroupRemoveIcon from '@material-symbols/svg-400/rounded/group_remove-fill.svg?react';
import { Cell, Column, Item, TableHeader } from 'react-stately';
import { Row } from 'react-stately';
import Table from '@/components/Table';
import Pagination from '@/components/Pagination';
import { OnboardingStatus } from '../../utils';
import { TableBody } from 'react-stately';
import EmptyTableState from '@/components/Table/EmptyState';
import Spinner from '@/components/Spinner';
import type { OrganizationUser } from '../../utils';
import Badge from '@/components/Badge';
import type { Key } from 'react';
import { useEffect, useState } from 'react';
import { produce } from 'immer';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useGetOrganizationUsers from '../../hooks/useGetOrganizationUsers';
import { downloadCsv } from '@/utils/downloadCsv';
import useUser from '@/hooks/useUser';
import { filtersToParams } from '../../hooks/useApiRequest';
import isNonEmptyString from '@/utils/isNonEmptyString';
import useOpenSignedOutModalDialog from '@/hooks/useOpenSignedOutModalDialog';
import useOpenErrorModalDialog from '@/hooks/useOpenErrorModalDialog';
import { useOverlayTriggerState } from 'react-stately';

import * as S from './styles';
import ButtonWithMenu from '@/components/Buttons/ButtonWithMenu';
import TeamFilters from './TeamFilters';

import MoreIcon from '@material-design-icons/svg/round/more_horiz.svg?react';
import AddUserModal from './Modals/AddUserModal';
import BulkAddUsersModal from './Modals/BulkAddUsersModal';
import BulkRemoveUsersModal from './Modals/BulkRemoveUsersModal';
import { AnimatePresence } from 'framer-motion';
import ViewUser from './User';
import RemoveUserModal from './Modals/RemoveUserModal';

enum SendEmailReminderButtonState {
  Hidden,
  Sending,
  Sent,
  Unsent
}

const Team = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userId } = useParams<{ userId?: string }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const { bearerToken, user } = useUser();
  const openErrorModalDialog = useOpenErrorModalDialog();
  const openSignedOutModalDialog = useOpenSignedOutModalDialog();
  const [previousPath, setPreviousPath] = useState<string | undefined>(undefined);
  const [singleUserToRemove, setSingleUserToRemove] = useState<string | undefined>(undefined);

  const {
    close: closeRemoveUserModal,
    isOpen: removeUserModalIsOpen,
    open: openRemoveUserModal
  } = useOverlayTriggerState({});

  const { close: closeAddUserModal, isOpen: addUserModalIsOpen } = useOverlayTriggerState({
    defaultOpen: location.pathname.endsWith('/add')
  });

  const { close: closeBulkAddUsersModal, isOpen: bulkAddUsersModalIsOpen } = useOverlayTriggerState(
    {
      defaultOpen: location.pathname.endsWith('/add/bulk')
    }
  );

  const { close: closeRemoveBulkUsersModal, isOpen: removeBulkUsersModalIsOpen } =
    useOverlayTriggerState({
      defaultOpen: location.pathname.endsWith('/remove/bulk')
    });

  const { close: closeViewUserModal, isOpen: viewUserModalIsOpen } = useOverlayTriggerState({
    defaultOpen: location.pathname.endsWith('/view') || location.pathname.endsWith('/edit')
  });

  const [currentPage, setCurrentPage] = useState<number>(
    searchParams.get('page') !== null ? Number(searchParams.get('page')) : 1
  );
  const [isExporting, setIsExporting] = useState(false);
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column: 'full_name',
    direction: 'ascending'
  });

  const organizationId = user.organization_memberships.find(
    membership => membership.member_role === 'superuser'
  )?.organization_id;
  const organizationName = user.organization_memberships.find(
    membership => membership.member_role === 'superuser'
  )?.organization.name;

  const {
    isFetchingOrgUsers,
    totalUserPages,
    updateOrganizationFilters,
    users: organizationUsers
  } = useGetOrganizationUsers(
    {
      full_name: searchParams.get('full_name') ?? '',
      clinical:
        searchParams.get('clinical') !== null ? Boolean(searchParams.get('clinical')) : undefined,
      member_role:
        searchParams.get('member_role') !== null
          ? (searchParams.get('member_role') as 'member' | 'superuser')
          : undefined,
      status:
        searchParams.get('status') === 'active'
          ? 'activated'
          : searchParams.get('status') === 'onboarding' || searchParams.get('status') === 'invited'
            ? 'created'
            : undefined,
      completed_onboarding:
        searchParams.get('status') === 'active'
          ? true
          : searchParams.get('status') === 'onboarding'
            ? false
            : undefined,
      order_by: {
        column: searchParams.get('order_by[column]') ?? 'full_name',
        dir: (searchParams.get('order_by[dir]') ?? 'asc') as 'asc' | 'desc'
      },
      page: parseInt(searchParams.get('page') ?? '1')
    },
    organizationId
  );
  const [users, setUsers] = useState<OrganizationUser[]>([]);

  useEffect(() => {
    setUsers(organizationUsers);
  }, [organizationUsers]);

  const handleProvidersExportButtonPress = async () => {
    if (organizationId === undefined) {
      return;
    }

    setIsExporting(true);

    const exportFilters = {
      full_name: searchParams.get('full_name') ?? '',
      clinical:
        searchParams.get('clinical') !== null ? Boolean(searchParams.get('clinical')) : undefined,
      member_role:
        searchParams.get('member_role') !== null
          ? (searchParams.get('member_role') as 'member' | 'superuser')
          : undefined,
      status:
        searchParams.get('status') === 'active'
          ? 'activated'
          : searchParams.get('status') === 'onboarding' || searchParams.get('status') === 'invited'
            ? 'created'
            : undefined,
      completed_onboarding:
        searchParams.get('status') === 'active'
          ? true
          : searchParams.get('status') === 'onboarding'
            ? false
            : undefined
    };
    const params = filtersToParams(exportFilters);

    const usersExportUrl: RequestInfo = `${
      import.meta.env.VITE_API_V2_BASE_PATH
    }/organizations/${organizationId}/users/export${isNonEmptyString(params) ? `?${params}` : ''}`;

    const options: RequestInit = {
      headers: {
        Authorization: `Bearer ${bearerToken}`
      }
    };

    try {
      const response = await fetch(usersExportUrl, options);

      let isSignedOut = false;

      if (!response.ok) {
        if (response.status === 401) {
          openSignedOutModalDialog();
          isSignedOut = true;
        } else {
          throw new Error(`${response.status} (${response.statusText})`);
        }
      }

      if (isSignedOut) return;

      const date = new Date();
      const formattedDate = date
        .toLocaleString('en-US', {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric'
        })
        .replace(/\//g, '-');
      await downloadCsv(
        response,
        `Violet_Users_${organizationName?.replaceAll(' ', '')}_${formattedDate}.csv`
      );

      setIsExporting(false);
    } catch (error) {
      Sentry.captureException(error);
      openErrorModalDialog();
      setIsExporting(false);
    }
  };

  const handleSendEmailReminderButtonPress = (membershipId?: string) => async () => {
    if (membershipId === undefined) {
      return;
    }

    setUsers(
      produce(organizationUsers, draft => {
        draft.find(user => user.membershipId === membershipId)!.sendEmailReminderButtonState =
          SendEmailReminderButtonState.Sending;
      })
    );

    const url: RequestInfo = `${import.meta.env.VITE_API_BASE_PATH}/users/dashboard/organizations/send_invite/${membershipId}`;

    const options: RequestInit = {
      headers: {
        Authorization: `Bearer ${bearerToken}`
      },
      method: 'POST'
    };

    try {
      const response = await fetch(url, options);

      if (!response.ok) {
        if (response.status === 401) {
          openSignedOutModalDialog();
          return;
        } else {
          throw new Error(`${response.status} (${response.statusText})`);
        }
      }

      setUsers(
        produce(organizationUsers, draft => {
          draft.find(user => user.membershipId === membershipId)!.sendEmailReminderButtonState =
            SendEmailReminderButtonState.Sent;
        })
      );
    } catch (error) {
      setUsers(
        produce(organizationUsers, draft => {
          draft.find(user => user.membershipId === membershipId)!.sendEmailReminderButtonState =
            SendEmailReminderButtonState.Unsent;
        })
      );

      Sentry.captureException(error);
      openErrorModalDialog();
    }
  };

  const handleTableActions = (key: Key) => {
    if (key === 'export_csv') {
      handleProvidersExportButtonPress();
    } else if (key === 'add_user') {
      const currentPath = location.pathname + location.search;
      navigate(`/dashboard/my-organization/team/add${location.search}`, {
        state: { previousPath: currentPath }
      });
    } else if (key === 'bulk_add_users') {
      const currentPath = location.pathname + location.search;
      navigate(`/dashboard/my-organization/team/add/bulk${location.search}`, {
        state: { previousPath: currentPath }
      });
    } else if (key === 'remove_bulk') {
      const currentPath = location.pathname + location.search;
      navigate(`/dashboard/my-organization/team/remove/bulk${location.search}`, {
        state: { previousPath: currentPath }
      });
    }
  };

  const handleRowAction = (membershipId?: string, userId?: string) => (key: Key) => {
    if (key === 'send_reminder') {
      handleSendEmailReminderButtonPress(membershipId)();
    } else if (key === 'view') {
      const currentPath = location.pathname + location.search;
      navigate(`/dashboard/my-organization/team/${userId}/view`, {
        state: { previousPath: currentPath }
      });
    } else if (key === 'edit') {
      const currentPath = location.pathname + location.search;
      navigate(`/dashboard/my-organization/team/${userId}/edit`, {
        state: { previousPath: currentPath }
      });
    } else if (key === 'remove') {
      setSingleUserToRemove(userId);
      openRemoveUserModal();
    }
  };

  // Get the previousPath from location state when the component mounts
  useEffect(() => {
    if (location.state?.previousPath) {
      setPreviousPath(location.state.previousPath);
    }
  }, [location.state]);

  const handleModalClose = (closeFn: () => void) => {
    closeFn();
    if (previousPath !== undefined) {
      // Parse the previous path to separate pathname and search
      const [pathname, search] = previousPath.split('?');
      navigate(pathname + (search ? `?${search}` : ''), {
        state: { previousPath: undefined }
      });
      setPreviousPath(undefined);
    } else {
      navigate(`/dashboard/my-organization/team${location.search}`);
    }
  };

  useEffect(() => {
    searchParams.set('page', currentPage.toString());
  }, [currentPage, searchParams]);

  useEffect(() => {
    setCurrentPage(
      searchParams.get('page') !== null ? parseInt(searchParams.get('page') ?? '1') : 1
    );
    setSortDescriptor({
      column: searchParams.get('order_by[column]') ?? 'full_name',
      direction: searchParams.get('order_by[dir]') === 'asc' ? 'ascending' : 'descending'
    });
  }, [searchParams]);

  useEffect(() => {
    updateOrganizationFilters({
      clinical:
        searchParams.get('clinical') !== null ? Boolean(searchParams.get('clinical')) : undefined,
      full_name: searchParams.get('full_name') ?? undefined,
      member_role:
        searchParams.get('member_role') !== null
          ? (searchParams.get('member_role') as 'member' | 'superuser')
          : undefined,
      order_by: {
        column: searchParams.get('order_by[column]') ?? 'full_name',
        dir: (searchParams.get('order_by[dir]') ?? 'asc') as 'asc' | 'desc'
      },
      page: parseInt(searchParams.get('page') ?? '1'),
      status:
        searchParams.get('status') === 'active'
          ? 'activated'
          : searchParams.get('status') === 'onboarding' || searchParams.get('status') === 'invited'
            ? 'created'
            : undefined,
      completed_onboarding:
        searchParams.get('status') === 'active'
          ? true
          : searchParams.get('status') === 'onboarding'
            ? false
            : undefined
    });
  }, [searchParams, updateOrganizationFilters]);

  return (
    <>
      <PageTitle
        description="Inclusivity insights are based on the overall cultural competence and diversity of your network. Violet generates these insights using a proprietary benchmarking."
        title="Team"
        titleVariant="h1"
      />
      <S.FiltersAndActionsWrapper>
        <TeamFilters
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          sortDescriptor={sortDescriptor}
        />
        <ButtonWithMenu
          data-cy="team-actions-menu"
          disabledKeys={isExporting ? ['export_csv'] : []}
          label="Actions"
          onAction={handleTableActions}
        >
          <Item
            key="export_csv"
            textValue="Export CSV"
          >
            <S.RowActionItem>
              <ExportIcon />
              {isExporting ? (
                <S.RowActionItem>
                  <Spinner />
                  Exporting...
                </S.RowActionItem>
              ) : (
                'Export CSV'
              )}
            </S.RowActionItem>
          </Item>
          <Item
            key="add_user"
            textValue="Add user"
          >
            <S.RowActionItem>
              <PersonAddIcon />
              Add user
            </S.RowActionItem>
          </Item>
          <Item
            key="bulk_add_users"
            textValue="Bulk add users"
          >
            <S.RowActionItem>
              <GroupAddIcon />
              Bulk add users
            </S.RowActionItem>
          </Item>
          <Item
            key="remove_bulk"
            textValue="Bulk remove users"
          >
            <S.RowActionItem>
              <GroupRemoveIcon />
              Bulk remove users
            </S.RowActionItem>
          </Item>
        </ButtonWithMenu>
      </S.FiltersAndActionsWrapper>
      {isFetchingOrgUsers ? (
        <Spinner withWrapper />
      ) : (
        <>
          <Table
            aria-label="Users"
            data-cy="organization-members"
            renderEmptyState={() => (
              <EmptyTableState
                colSpan={6}
                message="No team members have been found."
              />
            )}
            sortDescriptor={sortDescriptor}
            onSortChange={({ column, direction }) => {
              setSortDescriptor({ column, direction });
              setCurrentPage(1);
              setSearchParams(prev => {
                if (prev.get('page') !== null) {
                  prev.delete('page');
                }
                prev.set('order_by[column]', column as string);
                prev.set('order_by[dir]', direction === 'ascending' ? 'asc' : 'desc');
                return prev;
              });
            }}
          >
            <TableHeader>
              <Column
                key="full_name"
                allowsSorting
              >
                Name
              </Column>
              <Column key="email">Email</Column>
              <Column key="role">Role</Column>
              <Column key="user_type">User type</Column>
              <Column key="onboarded">Status</Column>
              <Column key="actions">
                <S.CenterWrapper>Actions</S.CenterWrapper>
              </Column>
            </TableHeader>
            <TableBody>
              {users.map((member: OrganizationUser) => (
                <Row
                  key={member.id}
                  data-cy="organization-member-row"
                >
                  <Cell>{member.name}</Cell>
                  <Cell>{member.email}</Cell>
                  <Cell>{member.memberRole === 'superuser' ? 'Admin' : 'Member'}</Cell>
                  <Cell>{member.isClinical ? 'Clinical' : 'Non-clinical'}</Cell>
                  <Cell>
                    {member.onboardingStatus === OnboardingStatus.Complete ? (
                      <Badge
                        color="green"
                        size="small"
                      >
                        Active
                      </Badge>
                    ) : member.inviteStatus === 'can send' ||
                      member.inviteStatus === 'already sent' ? (
                      <Badge
                        color="gray"
                        size="small"
                      >
                        Invited
                      </Badge>
                    ) : (
                      <Badge
                        color="yellow"
                        size="small"
                      >
                        Onboarding in progress
                      </Badge>
                    )}
                  </Cell>
                  <Cell>
                    <S.CenterWrapper>
                      <ButtonWithMenu
                        aria-label="Actions"
                        data-cy="user-actions-menu"
                        disabledKeys={['reminder_sent']}
                        trailingIcon={MoreIcon}
                        variant="icon"
                        onAction={handleRowAction(member.membershipId, member.id)}
                      >
                        {member.sendEmailReminderButtonState ===
                        SendEmailReminderButtonState.Sent ? (
                          <Item
                            key="reminder_sent"
                            textValue="Email reminder sent"
                          >
                            <S.RowActionItem>
                              <S.EmailReminderCheck />
                              Email reminder sent
                            </S.RowActionItem>
                          </Item>
                        ) : member.sendEmailReminderButtonState ===
                            SendEmailReminderButtonState.Sending ||
                          member.sendEmailReminderButtonState ===
                            SendEmailReminderButtonState.Unsent ? (
                          <Item
                            key="send_reminder"
                            textValue="Send email reminder"
                          >
                            {member.sendEmailReminderButtonState ===
                            SendEmailReminderButtonState.Sending ? (
                              <Spinner />
                            ) : (
                              'Send email reminder'
                            )}
                          </Item>
                        ) : null}
                        <Item
                          key="view"
                          textValue="View user"
                        >
                          View user
                        </Item>
                        <Item
                          key="edit"
                          textValue="Edit user"
                        >
                          Edit user
                        </Item>
                        <Item
                          key="remove"
                          textValue="Remove user"
                        >
                          Remove user
                        </Item>
                      </ButtonWithMenu>
                    </S.CenterWrapper>
                  </Cell>
                </Row>
              ))}
            </TableBody>
          </Table>
          <Pagination
            currentPage={currentPage}
            setPage={setCurrentPage}
            totalPages={totalUserPages}
          />
        </>
      )}
      <AddUserModal
        isOpen={addUserModalIsOpen}
        organizationId={organizationId}
        onClose={() => handleModalClose(closeAddUserModal)}
      />
      <BulkAddUsersModal
        isOpen={bulkAddUsersModalIsOpen}
        organizationId={organizationId}
        onClose={() => handleModalClose(closeBulkAddUsersModal)}
      />
      <BulkRemoveUsersModal
        isOpen={removeBulkUsersModalIsOpen}
        organizationId={organizationId}
        onClose={() => handleModalClose(closeRemoveBulkUsersModal)}
      />
      <RemoveUserModal
        isOpen={removeUserModalIsOpen}
        organizationId={organizationId}
        userId={singleUserToRemove}
        onClose={() => {
          setSingleUserToRemove(undefined);
          handleModalClose(closeRemoveUserModal);
        }}
      />
      <AnimatePresence>
        {viewUserModalIsOpen && userId !== undefined && (
          <ViewUser
            close={() => handleModalClose(closeViewUserModal)}
            organizationId={organizationId ?? ''}
            userId={userId}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default Team;
