import greenCheckIcon from '@material-design-icons/svg/round/check.svg?react';
import { styled } from 'styled-components';

export const CenterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
`;

export const EmailReminderCheck = styled(greenCheckIcon)`
  width: 1rem;
  height: 1rem;
  fill: var(--gray-400) !important;
`;

export const FiltersAndActionsWrapper = styled.div`
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RowActionItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;
