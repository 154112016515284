import ModalDialog from '@/components/Modals/ModalDialog';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  organizationId?: string;
}

const BulkRemoveUsersModal = ({ isOpen, onClose, organizationId }: Props) =>
  isOpen && (
    <ModalDialog
      isDismissable
      data-cy="bulk-remove-users-modal"
      isOpen={isOpen}
      title="Bulk remove Team Members"
      onClose={onClose}
    >
      <div>Bulk remove users form content {organizationId}</div>
    </ModalDialog>
  );

export default BulkRemoveUsersModal;
