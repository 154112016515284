import ModalDialog from '@/components/Modals/ModalDialog';
import { useLocation, useNavigate } from 'react-router-dom';
interface Props {
  isOpen: boolean;
  onClose: () => void;
  organizationId?: string;
  userId?: string;
}

const EditUserModal = ({ isOpen, onClose, organizationId, userId }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const handleClose = () => {
    onClose();
    if (location.state?.previousPath) {
      navigate(location.state.previousPath);
    }
  };
  return (
    isOpen && (
      <ModalDialog
        isDismissable
        data-cy="edit-user-modal"
        isOpen={isOpen}
        title="Edit user"
        onClose={handleClose}
      >
        <div>
          Edit user {userId} content {organizationId}
        </div>
      </ModalDialog>
    )
  );
};

export default EditUserModal;
