import styled from 'styled-components';

import { Option } from '../../../../FormFields/ComboBox/ListBox/Option/styles';

export const MenuItem = styled(Option)`
  &.is-disabled {
    cursor: not-allowed;
    color: var(--gray-500);
  }
`;
