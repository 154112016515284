import { motion } from 'framer-motion';
import { OverlayContainer } from 'react-aria';
import ModalDialogFullscreen from '@/components/Modals/ModalDialogFullscreen';
import styled from 'styled-components';

export const AnimationWrapper = styled(motion.div)`
  top: 0;
  left: 0;
  position: fixed;
  z-index: 100;
  width: 100vw;
  height: 100vh;
`;

export const FullModal = styled(ModalDialogFullscreen)``;

export const FullOverlay = styled(OverlayContainer)`
  > div {
    z-index: 100;
  }
`;
