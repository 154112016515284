import {
  organizationFiltersToSearch,
  organizationSearchToFilters
} from '../../organizationFilters';
import { sortFilterParams } from '@/utils/filterUtils';
import { filtersObjectToParams } from '@/utils/filterUtils';
import type { AriaSearchFieldProps, Key } from 'react-aria';
import FilterDropdown from '@/components/FilterDropdown';
import type { AriaSelectProps } from 'react-aria';
import SearchField from '@/components/FormFields/SearchField';
import type { SortDescriptor } from 'react-stately';
import { Item } from 'react-stately';

import * as S from './styles';
import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import isNonEmptyString from '@/utils/isNonEmptyString';

interface Props {
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  sortDescriptor: SortDescriptor;
}

const TeamFilters = ({ currentPage, setCurrentPage, sortDescriptor }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const draftState = useRef<{
    clinical: 'true' | 'false' | 'all';
    full_name: string;
    member_role: 'member' | 'superuser' | 'all';
    status: 'active' | 'onboarding' | 'invited' | 'all';
  }>({
    full_name: searchParams.get('full_name') ?? '',
    clinical:
      searchParams.get('clinical') !== null
        ? (searchParams.get('clinical') as 'true' | 'false')
        : 'all',
    member_role:
      searchParams.get('member_role') !== null
        ? (searchParams.get('member_role') as 'member' | 'superuser')
        : 'all',
    status:
      searchParams.get('status') !== null
        ? (searchParams.get('status') as 'active' | 'onboarding' | 'invited')
        : 'all'
  });
  const [appliedFilters, setAppliedFilters] = useState<Set<Key>>(
    new Set(
      Array.from(searchParams.entries())
        .filter(([key]) => !key.includes('order_by') && !key.includes('page'))
        .flatMap(([key, value]) =>
          value.split(',').map(v => organizationSearchToFilters(key, v) as Key)
        )
    )
  );

  const handleSearchByNameFieldChange: AriaSearchFieldProps['onChange'] = value => {
    draftState.current.full_name = value;
  };

  const handleUserTypeSelectionChange: AriaSelectProps<object>['onSelectionChange'] = key => {
    draftState.current.clinical = key as 'true' | 'false' | 'all';
  };

  const handleUserRoleSelectionChange: AriaSelectProps<object>['onSelectionChange'] = key => {
    draftState.current.member_role = key as 'member' | 'superuser' | 'all';
  };

  const handleUserStatusSelectionChange: AriaSelectProps<object>['onSelectionChange'] = key => {
    draftState.current.status = key as 'active' | 'onboarding' | 'invited' | 'all';
  };

  const preserveChanges = () => {
    const updatedFilters = [
      `Provider:${draftState.current.full_name}`,
      `User role:${draftState.current.member_role}`,
      `User type:${draftState.current.clinical}`,
      `User status:${draftState.current.status}`
    ].filter(
      entry =>
        !entry.includes('null') &&
        !entry.includes('undefined') &&
        !entry.includes('all') &&
        isNonEmptyString(entry.split(':')[1])
    );
    setCurrentPage(1);
    setAppliedFilters(new Set(updatedFilters));
    refreshURLparams(new Set(updatedFilters));
  };

  const handleClearFilters = () => {
    setCurrentPage(1);
    draftState.current = {
      full_name: '',
      clinical: 'all',
      member_role: 'all',
      status: 'all'
    };
    setAppliedFilters(new Set());
    refreshURLparams(new Set());
  };

  const handleRemoveFilter = (keys: Set<Key>) => {
    Array.from(keys).forEach(key => {
      const keyString = key.toString();
      if (keyString.includes('Provider:')) {
        draftState.current.full_name = '';
      } else if (keyString.includes('User type:')) {
        draftState.current.clinical = 'all';
      } else if (keyString.includes('User role:')) {
        draftState.current.member_role = 'all';
      } else if (keyString.includes('User status:')) {
        draftState.current.status = 'all';
      }
    });
    setCurrentPage(1);
    const updatedFilters = new Set(Array.from(appliedFilters).filter(key => !keys.has(key)));
    setAppliedFilters(updatedFilters);
    refreshURLparams(updatedFilters);
  };

  const refreshURLparams = (updatedFilters: Set<Key>) => {
    const finalParams = filtersObjectToParams(organizationFiltersToSearch, updatedFilters);
    const orderAndPageParams: [string, string][] = [
      ['order_by[column]', sortDescriptor.column as string],
      ['order_by[dir]', sortDescriptor.direction === 'ascending' ? 'asc' : 'desc'],
      ['page', currentPage.toString()]
    ];
    const sortedParams = sortFilterParams(orderAndPageParams.concat(finalParams));
    setSearchParams(sortedParams);
  };

  useEffect(() => {
    setAppliedFilters(
      new Set(
        Array.from(searchParams.entries())
          .filter(([key]) => !key.includes('order_by') && !key.includes('page'))
          .flatMap(([key, value]) =>
            value.split(',').map(v => organizationSearchToFilters(key, v) as Key)
          )
      )
    );

    draftState.current = {
      full_name: searchParams.get('full_name') ?? '',
      clinical:
        searchParams.get('clinical') !== null
          ? (searchParams.get('clinical') as 'true' | 'false')
          : 'all',
      member_role:
        searchParams.get('member_role') !== null
          ? (searchParams.get('member_role') as 'member' | 'superuser')
          : 'all',
      status:
        searchParams.get('status') !== null
          ? (searchParams.get('status') as 'active' | 'onboarding' | 'invited')
          : 'all'
    };
  }, [searchParams]);

  return (
    <FilterDropdown>
      <FilterDropdown.Filters onApplyChanges={preserveChanges}>
        <SearchField
          aria-label="Search by user name"
          data-cy="name-search-field"
          defaultValue={draftState.current.full_name}
          placeholder="User name"
          onChange={handleSearchByNameFieldChange}
        />
        <S.TeamMemberSelect
          aria-label="Filter by user role"
          data-cy="user-role-filter"
          defaultSelectedKey={draftState.current.member_role}
          placeholder="Filter by user role"
          onSelectionChange={handleUserRoleSelectionChange}
        >
          <Item key="all">All roles</Item>
          <Item key="member">Member</Item>
          <Item key="superuser">Admin</Item>
        </S.TeamMemberSelect>
        <S.TeamMemberSelect
          aria-label="Filter by user type"
          data-cy="user-type-filter"
          defaultSelectedKey={draftState.current.clinical}
          placeholder="Filter by user type"
          onSelectionChange={handleUserTypeSelectionChange}
        >
          <Item key="all">All types</Item>
          <Item key="true">Clinical</Item>
          <Item key="false">Non-clinical</Item>
        </S.TeamMemberSelect>
        <S.TeamMemberSelect
          aria-label="Filter by user status"
          data-cy="user-status-filter"
          defaultSelectedKey={draftState.current.status}
          placeholder="Filter by user status"
          onSelectionChange={handleUserStatusSelectionChange}
        >
          <Item key="all">All statuses</Item>
          <Item key="invited">Invited</Item>
          <Item key="onboarding">Onboarding in progress</Item>
          <Item key="active">Active</Item>
        </S.TeamMemberSelect>
      </FilterDropdown.Filters>
      <FilterDropdown.Tags
        onClear={handleClearFilters}
        onRemove={handleRemoveFilter}
      >
        {Array.from(appliedFilters).map(key => {
          const label = key.toString().split(':')[0];
          let value = key.toString().split(':')[1];
          if (label.toLowerCase().includes('user type')) {
            value = value === 'true' ? 'Clinical' : 'Non-clinical';
          }
          return (
            <Item key={key}>
              {label}
              {': '}
              {value}
            </Item>
          );
        })}
      </FilterDropdown.Tags>
    </FilterDropdown>
  );
};

export default TeamFilters;
